
/*@font-face {*/
/*  font-family: 'Pretendard-Regular';*/
/*  src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');*/
/*  font-weight: 400;*/
/*  font-style: normal;*/
/*}*/

body {
  margin: 0;
  font-family: Pretendard-Regular, Pretendard, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

a{
  text-decoration: none;
}

button{
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}